import { filter, unionBy } from "lodash";
import * as Types from "../types/TimeOffType";
import * as TypesApproval from "../types/ApprovalType";
import moment from "moment";

const initialState = {
  categories: [],
  peopleOnLeaveDays: {},
  item: {
    timeOffs: [],
    timeOffHistories: [],
  },
};

const timeOffReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types?.GET_TIME_OFF_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action?.result,
      };
    case Types.CREATE_TIME_OFF_CATEGORY_SUCCESS: {
      const categories = [action?.result, ...state?.categories];
      return {
        ...state,
        categories,
      };
    }
    case Types.UPDATE_TIME_OFF_CATEGORY_SUCCESS: {
      const categories = state?.categories?.map((x) => {
        if (x?.id === action?.result?.id) {
          return action?.result;
        }
        return x;
      });
      return {
        ...state,
        categories,
      };
    }
    case Types.DELETE_TIME_OFF_CATEGORY_SUCCESS: {
      const categories = state?.categories?.filter(
        (category) => category?.id !== action?.result?.id
      );
      return {
        ...state,
        categories,
      };
    }
    case Types.CREATE_PEOPLE_ON_LEAVE_DAYS_SUCCESS: {
      let from_date = new Date(action.result.from_date);
      let to_date = new Date(action.result.to_date);
      for(let day = from_date; day <= to_date; day.setDate(day.getDate() + 1)) {
        const date = moment(day).format('MM-DD-YYYY').toString()
        if(state.peopleOnLeaveDays.hasOwnProperty(date)){
          state.peopleOnLeaveDays[date].push(action.result);
        } else {
          state.peopleOnLeaveDays[date] = [action.result];
        }
      }
      return {
        ...state,
      };
    }
    case Types.GET_PEOPLE_ON_LEAVE_DAYS_SUCCESS: {
      const peopleOnLeaveDays = {
        ...state?.peopleOnLeaveDays,
        ...action?.result,
      };
      return {
        ...state,
        peopleOnLeaveDays,
      };
    }
    case Types.UPDATE_PEOPLE_ON_LEAVE_DAYS_SUCCESS: {
      for(let date in state.peopleOnLeaveDays){
        state.peopleOnLeaveDays[date] = state.peopleOnLeaveDays[date].filter(item => item?.id !== action?.result?.id);
      }

      let from_date = new Date(action.result.from_date);
      let to_date = new Date(action.result.to_date);
      for(let day = from_date; day <= to_date; day.setDate(day.getDate() + 1)) {
        const date = moment(day).format('MM-DD-YYYY').toString()
        if(state.peopleOnLeaveDays.hasOwnProperty(date)){
          state.peopleOnLeaveDays[date].push(action.result);
        } else {
          state.peopleOnLeaveDays[date] = [action.result];
        }
      }

      return {
        ...state,
      }
    }
    case Types.DELETE_PEOPLE_ON_LEAVE_DAYS_SUCCESS: {
      for(let date in state.peopleOnLeaveDays){
        state.peopleOnLeaveDays[date] = state.peopleOnLeaveDays[date].filter(item => item?.id !== action?.result?.id);
      }

      return {
        ...state,
      }
    }
    case Types.GET_EMPLOYEE_TIME_OFF_HISTORIES_SUCCESS: {
      const item = { ...state?.item, timeOffHistories: action?.result };
      return {
        ...state,
        item,
      };
    }
    case TypesApproval.GRANT_APPROVAL_SUCCESS: {
      let item = action.result.timeoff_histories.employee;
      item = {...item, note: action.result.timeoff_histories.note}
      let from_date = new Date(parseInt(action.result.timeoff_histories.from_date, 10));
      let to_date = new Date(parseInt(action.result.timeoff_histories.to_date, 10));

      item = {...item,
        from_date: moment(from_date).format('MM-DD-YYYY'),
        to_date: moment(to_date).format('MM-DD-YYYY'),
      }
      for(let day = from_date; day <= to_date; day.setDate(day.getDate() + 1)) {
        const date = moment(day).format('MM-DD-YYYY').toString();
        if(state.peopleOnLeaveDays.hasOwnProperty(date)){
          state.peopleOnLeaveDays[date].push(item);
        } else {
          state.peopleOnLeaveDays[date] = [item];
        }
      }
    }
    default:
      return state;
  }
};

export default timeOffReducer;
