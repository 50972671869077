import React from "react";
import PERMISSION from "./constants/permission";

const routes = [
  {
    is_title: true,
    is_redirect: false,
    title: "Home",
    group: "home",
    path: "/",
    exact: false,
    icon: "fa-home",
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "Dashboard",
        name: "Home",
        path: PERMISSION.HOME.VIEW.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.HOME.VIEW.permission,
        exact: false,
        show: false,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "Company Profile",
    group: "company",
    path: "",
    exact: false,
    icon: "fa-building",
    isAllowedAdmin: true,
    isAllowedManager: false,
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "Company Overview",
        name: "overview",
        path: PERMISSION.COMPANY.VIEW_OVERVIEW.route,
        icon: "icon-handbag",
        group: "company",
        permission_key: PERMISSION.COMPANY.VIEW_OVERVIEW.permission,
        exact: false,
        show: false,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "People",
    group: "employees",
    path: "",
    exact: false,
    icon: "fa-users",
    isAllowedAdmin: false,
    isAllowedManager: false,
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "Employees",
        name: "list",
        path: PERMISSION.PEOPLE.VIEW.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.PEOPLE.VIEW.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Directory",
        name: "directory",
        path: PERMISSION.PEOPLE.VIEW_DIRECTORY.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.PEOPLE.VIEW_DIRECTORY.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Org Chart",
        name: "org_chart",
        path: PERMISSION.PEOPLE.VIEW_ORG_CHART.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.PEOPLE.VIEW_ORG_CHART.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "Employees",
    group: "employee",
    path: "",
    exact: false,
    icon: "fa-user",
    show: false,
    sub_menu: [
      {
        is_redirect: false,
        title: "Personal Info",
        name: "detail",
        path: PERMISSION.EMPLOYEE.VIEW_PERSONAL_INFO.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.EMPLOYEE.VIEW_PERSONAL_INFO.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Job Info",
        name: "job_info",
        path: PERMISSION.EMPLOYEE.VIEW_JOB_INFO.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.EMPLOYEE.VIEW_JOB_INFO.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Emergency",
        name: "emergency-contact",
        path: PERMISSION.EMPLOYEE.VIEW_EMERGENCY_CONTACT.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.EMPLOYEE.VIEW_EMERGENCY_CONTACT.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Benefits",
        name: "benefits",
        path: PERMISSION.EMPLOYEE.VIEW_BENEFITS.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.EMPLOYEE.VIEW_BENEFITS.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Time Off",
        name: "time_off",
        path: PERMISSION.EMPLOYEE.VIEW_TIME_OFFS.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.EMPLOYEE.VIEW_TIME_OFFS.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Pay Stubs",
        name: "pay_stubs",
        path: PERMISSION.EMPLOYEE.VIEW_PAY_STUBS.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.EMPLOYEE.VIEW_PAY_STUBS.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "Pay Schedule",
    group: "pay_schedule",
    path: "",
    exact: false,
    icon: "fa-calendar",
    isAllowedAdmin: true,
    isAllowedManager: false,
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "List",
        name: "pay_schedule",
        path: PERMISSION.PAY_SCHEDULE.VIEW_LIST.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.PAY_SCHEDULE.VIEW_LIST.permission,
        exact: false,
        show: true,
      },
    ],
  },

  {
    is_title: true,
    is_redirect: false,
    title: "Benefits",
    group: "benefit",
    path: "",
    exact: false,
    icon: "fa-heartbeat",
    isAllowedAdmin: true,
    isAllowedManager: false,
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "Plans",
        name: "benefit_plans",
        path: PERMISSION.BENEFIT.VIEW_PLANS.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.BENEFIT.VIEW_PLANS.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Categories",
        name: "benefit_categories",
        path: PERMISSION.BENEFIT.VIEW_CATEGORIES.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.BENEFIT.VIEW_CATEGORIES.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "My Profile",
    group: "me",
    path: "",
    exact: false,
    icon: "fa-user",
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "Personal Info",
        name: "personal-info",
        path: PERMISSION.ME.VIEW_PERSONAL_INFO.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.ME.VIEW_PERSONAL_INFO.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Job Info",
        name: "job-info",
        path: PERMISSION.ME.VIEW_JOB_INFO.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.ME.VIEW_JOB_INFO.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Emergency",
        name: "emergency-contact",
        path: PERMISSION.ME.VIEW_EMERGENCY_CONTACT.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.EMPLOYEE.VIEW_EMERGENCY_CONTACT.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Benefits",
        name: "benefits",
        path: PERMISSION.ME.VIEW_BENEFITS.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.ME.VIEW_BENEFITS.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Time Off",
        name: "time_off",
        path: PERMISSION.ME.VIEW_TIME_OFFS.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.ME.VIEW_TIME_OFFS.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Pay Stubs",
        name: "pay_stubs",
        path: PERMISSION.ME.VIEW_PAY_STUBS.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.ME.VIEW_PAY_STUBS.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "Files",
    group: "files",
    path: "",
    exact: false,
    icon: "fa-file",
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "Files",
        name: "file_management",
        path: PERMISSION.FILES.MANEGEMENT.route,
        icon: "fa-file",
        group: "data",
        permission_key: PERMISSION.FILES.MANEGEMENT.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Categories",
        name: "benefit_categories",
        path: PERMISSION.BENEFIT.VIEW_CATEGORIES.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.BENEFIT.VIEW_CATEGORIES.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "Approval",
    group: "approval",
    path: "",
    exact: false,
    icon: "fa-archive",
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "Approval",
        name: "request",
        path: PERMISSION.APPROVAL.VIEW_REQUESTS.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.APPROVAL.VIEW_REQUESTS.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Histories",
        name: "calendar",
        path: PERMISSION.APPROVAL.VIEW_APPROVAL_HISTORIES.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.APPROVAL.VIEW_APPROVAL_HISTORIES.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Holidays",
        name: "calendar",
        path: PERMISSION.APPROVAL.VIEW_HOLIDAY.route,
        icon: "icon-handbag",
        group: "data",
        permission_key: PERMISSION.APPROVAL.VIEW_HOLIDAY.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "Time Off",
    group: "time_off",
    path: "",
    exact: false,
    icon: "fa-plane",
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "List",
        name: "list_policy",
        path: PERMISSION.TIME_OFF.VIEW_POLICIES.route,
        icon: "fa-file",
        group: "data",
        permission_key: PERMISSION.TIME_OFF.VIEW_POLICIES.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "Pay Roll",
    group: "payroll",
    path: "",
    exact: false,
    icon: "fa-credit-card",
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "People",
        name: "people",
        path: PERMISSION.PAY_ROLL.PEOPLE.route,
        icon: "fa-file",
        group: "data",
        permission_key: PERMISSION.PAY_ROLL.PEOPLE.permission,
        exact: false,
        show: true,
      },
      {
        is_redirect: false,
        title: "Pay Runs",
        name: "pay_runs",
        path: PERMISSION.PAY_ROLL.PAY_RUNS.route,
        icon: "fa-file",
        group: "data",
        permission_key: PERMISSION.PAY_ROLL.PAY_RUNS.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "Admin Companies",
    group: "companies",
    path: "",
    exact: false,
    icon: "fa-credit-card",
    isAllowedSuperAdmin: true,
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "Companies",
        name: "list",
        path: PERMISSION.ADMIN.MANAGE_COMPANIES.route,
        icon: "fa-file",
        group: "companies",
        permission_key: PERMISSION.ADMIN.MANAGE_COMPANIES.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "Customers",
    group: "customers",
    path: "",
    exact: false,
    icon: "fa-user",
    isAllowedSuperAdmin: true,
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "Account",
        name: "list",
        path: PERMISSION.ADMIN.MANAGE_CUSTOMERS.route,
        icon: "fa-file",
        group: "customers",
        permission_key: PERMISSION.ADMIN.MANAGE_CUSTOMERS.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "Admin Users",
    group: "users",
    path: "",
    exact: false,
    icon: "fa-user-secret",
    isAllowedSuperAdmin: true,
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "Account",
        name: "list",
        path: PERMISSION.ADMIN.MANAGE_USERS.route,
        icon: "fa-file",
        group: "users",
        permission_key: PERMISSION.ADMIN.MANAGE_USERS.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "Departments",
    group: "departments",
    path: "",
    exact: false,
    icon: "fa-building",
    isAllowedSuperAdmin: true,
    show: true,
    sub_menu: [
      {
        is_redirect: false,
        title: "List",
        name: "list",
        path: PERMISSION.ADMIN.MANAGE_DEPARTMENTS.route,
        icon: "fa-file",
        group: "departments",
        permission_key: PERMISSION.ADMIN.MANAGE_DEPARTMENTS.permission,
        exact: false,
        show: true,
      },
    ],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "",
    name: "",
    group: "errorPages",
    path: "/403",
    exact: false,
    icon: "mdi-ticket-percent",
    show: false,
    permission: "data",
    sub_menu: [],
  },
  {
    is_title: true,
    is_redirect: false,
    title: "",
    name: "",
    group: "errorPages",
    path: "/404",
    exact: false,
    icon: "mdi-ticket-percent",
    show: false,
    permission: "data",
    sub_menu: [],
  },
];

export default routes;
