import React, { Component } from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import Layout from "./Main";
import LoginRoute from "./LoginRoute";
import PrivateRoute from "./PrivateRoute";
import PERMISSION from "../constants/permission";

class MainCheck extends Component {
  render() {
    return (
      <Route>
        <Switch>
          <LoginRoute path='/login' component={Layout.Signin} />
          <PrivateRoute
            path={PERMISSION.HOME.VIEW.route}
            group={PERMISSION.HOME.VIEW.group}
            exact
            component={Layout.Home}
          />
          <PrivateRoute
            path={PERMISSION.PEOPLE.VIEW.route}
            group={PERMISSION.PEOPLE.VIEW.group}
            exact
            component={Layout.UserList}
          />
          <PrivateRoute
            path={PERMISSION.PEOPLE.VIEW_DIRECTORY.route}
            group={PERMISSION.PEOPLE.VIEW_DIRECTORY.group}
            exact
            component={Layout.UserDirectory}
          />
          <PrivateRoute
            path={PERMISSION.PEOPLE.VIEW_ORG_CHART.route}
            group={PERMISSION.PEOPLE.VIEW_ORG_CHART.group}
            exact
            component={Layout.UserOrgChart}
          />
          <PrivateRoute
            path={PERMISSION.EMPLOYEE.VIEW_PERSONAL_INFO.route}
            group={PERMISSION.EMPLOYEE.VIEW_PERSONAL_INFO.group}
            exact
            component={Layout.EmployeePersonalInfo}
          />
          <PrivateRoute
            path={PERMISSION.EMPLOYEE.VIEW_JOB_INFO.route}
            group={PERMISSION.EMPLOYEE.VIEW_JOB_INFO.group}
            exact
            component={Layout.EmployeeJobInfo}
          />
          <PrivateRoute
            path={PERMISSION.EMPLOYEE.VIEW_EMERGENCY_CONTACT.route}
            group={PERMISSION.EMPLOYEE.VIEW_EMERGENCY_CONTACT.group}
            exact
            component={Layout.EmployeeContacts}
          />
          <PrivateRoute
            path={PERMISSION.EMPLOYEE.VIEW_BENEFITS.route}
            group={PERMISSION.EMPLOYEE.VIEW_BENEFITS.group}
            exact
            component={Layout.EmployeeBenefits}
          />
          <PrivateRoute
            path={PERMISSION.EMPLOYEE.VIEW_TIME_OFFS.route}
            group={PERMISSION.EMPLOYEE.VIEW_TIME_OFFS.group}
            exact
            component={Layout.EmployeeTimeOffs}
          />
          <PrivateRoute
            path={PERMISSION.EMPLOYEE.VIEW_PAY_STUBS.route}
            group={PERMISSION.EMPLOYEE.VIEW_PAY_STUBS.group}
            exact
            component={Layout.EmployeePayStubs}
          />
          <PrivateRoute
            path={PERMISSION.ME.VIEW_PERSONAL_INFO.route}
            group={PERMISSION.ME.VIEW_PERSONAL_INFO.group}
            exact
            component={Layout.EmployeePersonalInfo}
          />
          <PrivateRoute
            path={PERMISSION.ME.VIEW_JOB_INFO.route}
            group={PERMISSION.ME.VIEW_JOB_INFO.group}
            exact
            component={Layout.EmployeeJobInfo}
          />
          <PrivateRoute
            path={PERMISSION.ME.VIEW_BENEFITS.route}
            group={PERMISSION.ME.VIEW_BENEFITS.group}
            exact
            component={Layout.EmployeeBenefits}
          />
          <PrivateRoute
            path={PERMISSION.ME.VIEW_TIME_OFFS.route}
            group={PERMISSION.ME.VIEW_TIME_OFFS.group}
            exact
            component={Layout.EmployeeTimeOffs}
          />
          <PrivateRoute
            path={PERMISSION.ME.VIEW_PAY_STUBS.route}
            group={PERMISSION.ME.VIEW_PAY_STUBS.group}
            exact
            component={Layout.EmployeePayStubs}
          />
          <PrivateRoute
            path={PERMISSION.ME.VIEW_EMERGENCY_CONTACT.route}
            group={PERMISSION.ME.VIEW_EMERGENCY_CONTACT.group}
            exact
            component={Layout.EmployeeContacts}
          />
          <PrivateRoute
            path={PERMISSION.BENEFIT.VIEW_PLANS.route}
            group={PERMISSION.BENEFIT.VIEW_PLANS.group}
            exact
            component={Layout.BenefitPlans}
          />
          <PrivateRoute
            path={PERMISSION.BENEFIT.VIEW_CATEGORIES.route}
            group={PERMISSION.BENEFIT.VIEW_CATEGORIES.group}
            exact
            component={Layout.BenefitCategories}
          />
          <PrivateRoute
            path={PERMISSION.PAY_SCHEDULE.VIEW_LIST.route}
            group={PERMISSION.PAY_SCHEDULE.VIEW_LIST.group}
            exact
            component={Layout.PaySchedule}
          />
          <PrivateRoute
            path={PERMISSION.TIME_OFF.VIEW_POLICIES.route}
            group={PERMISSION.TIME_OFF.VIEW_POLICIES.group}
            exact
            component={Layout.TimeOffs}
          />
          <PrivateRoute
            path={PERMISSION.APPROVAL.VIEW_REQUESTS.route}
            group={PERMISSION.APPROVAL.VIEW_REQUESTS.group}
            exact
            component={Layout.Approval}
          />
          <PrivateRoute
            path={PERMISSION.APPROVAL.VIEW_APPROVAL_HISTORIES.route}
            group={PERMISSION.APPROVAL.VIEW_APPROVAL_HISTORIES.group}
            exact
            component={Layout.ApprovalHistories}
          />
          <PrivateRoute
              path={PERMISSION.APPROVAL.VIEW_HOLIDAY.route}
              group={PERMISSION.APPROVAL.VIEW_HOLIDAY.group}
              exact
              component={Layout.Holidays}
          />
          <PrivateRoute
            path={PERMISSION.FILES.MANEGEMENT.route}
            group={PERMISSION.FILES.MANEGEMENT.group}
            exact
            component={Layout.Files}
          />
          <PrivateRoute
            path={PERMISSION.PAY_ROLL.PEOPLE.route}
            group={PERMISSION.PAY_ROLL.PEOPLE.group}
            exact
            component={Layout.EmplooyeesOnPayRoll}
          />
          <PrivateRoute
            path={PERMISSION.PAY_ROLL.PAY_RUNS.route}
            group={PERMISSION.PAY_ROLL.PAY_RUNS.group}
            exact
            component={Layout.PayRuns}
          />
          <PrivateRoute
            path={PERMISSION.COMPANY.VIEW_OVERVIEW.route}
            group={PERMISSION.COMPANY.VIEW_OVERVIEW.group}
            exact
            component={Layout.CompanyDetail}
          />
          <PrivateRoute
            path={PERMISSION.ADMIN.MANAGE_COMPANIES.route}
            group={PERMISSION.ADMIN.MANAGE_COMPANIES.group}
            exact
            component={Layout.Companies}
          />
          <PrivateRoute
            path={PERMISSION.ADMIN.MANAGE_CUSTOMERS.route}
            group={PERMISSION.ADMIN.MANAGE_CUSTOMERS.group}
            exact
            component={Layout.Customers}
          />
          <PrivateRoute
            path={PERMISSION.ADMIN.MANAGE_USERS.route}
            group={PERMISSION.ADMIN.MANAGE_USERS.group}
            exact
            component={Layout.Users}
          />
          <PrivateRoute
            path={PERMISSION.ADMIN.MANAGE_DEPARTMENTS.route}
            group={PERMISSION.ADMIN.MANAGE_DEPARTMENTS.group}
            exact
            component={Layout.Departments}
          />
          <PrivateRoute
            path='/403'
            group='errorPages'
            exact
            component={Layout.Error403}
          />
          <Route
            path=''
            group='errorPages'
            exact={false}
            component={Layout.Error}
          />
        </Switch>
      </Route>
    );
  }
}

export default withRouter(MainCheck);
