import {message, notification} from "antd";
import { call, put, takeLatest, fork, all } from "redux-saga/effects";

import * as Types from "../types/HolidayType";
import * as Func from "../../utils/functions";
import * as FunctionAction from "../actions/holidayAction";
import * as TimeOffAction from "../actions/timeOffAction";
import * as FunctionService from "../services/holidayService";

import TAG_DEFINE from "../../constants/common";

function* getHolidays() {
    yield  takeLatest(
        Types.GET_HOLIDAYS_REQUEST,
        Func.sagaWrapper(function* (action) {
            const result = yield call(
                FunctionService.getHolidays);
            yield put(FunctionAction.getHolidaysSuccess(result));
        }, errorHandle(Types.GET_HOLIDAYS_FAILURE))
    )
}

function* createHoliday() {
    yield takeLatest(
        Types.CREATE_HOLIDAY_REQUEST,
        Func.sagaWrapper(function* (action) {
            const result = yield call(
                FunctionService.createHoliday,
                action?.payload
            );
            yield put(FunctionAction.createHolidaySuccess(result));
            yield put(TimeOffAction.createPeopleOnLeaveDaysSuccess(result));
            message.success(TAG_DEFINE.VALIDATION.statusCode.code["201"]);
        }, errorHandle(Types.CREATE_HOLIDAY_FAILURE))
    );
}

function* updateHoliday() {
    yield takeLatest(
        Types.UPDATE_HOLIDAY_REQUEST,
        Func.sagaWrapper(function* (action) {
            const result = yield call(
                FunctionService.updateHoliday,
                action?.payload
            );
            yield put(FunctionAction.updateHolidaySuccess(result));
            yield put(TimeOffAction.updatePeopleOnLeaveDaysSuccess(result));
            notification.success({ message: "Update Success" });
        }, errorHandle(Types.UPDATE_HOLIDAY_FAILURE))
    );
}

function* deleteHoliday() {
    yield takeLatest(
        Types.DELETE_HOLIDAY_REQUEST,
        Func.sagaWrapper(function* (action) {
            yield call(FunctionService.deleteHoliday, action?.params);
            yield put(FunctionAction.deleteHolidaySuccess(action?.params));
            yield put(TimeOffAction.deletePeopleOnLeaveDaysSuccess(action?.params));
            notification.success({ message: "Success" });
        }, errorHandle(Types.DELETE_HOLIDAY_FAILURE))
    );
}

function errorHandle(errorActionType) {
    return Func.sagaErrorHandler(function* (e) {
        console.log(e)
        if (e?.errors) {
            notification?.error({ message: e?.errors[0]?.message });
        }
        yield put({ type: errorActionType, errors: e?.errors });
    });
}

export function* holidaySaga() {
    yield all([
        fork(createHoliday),
        fork(getHolidays),
        fork(updateHoliday),
        fork(deleteHoliday),
    ]);
}
