import * as Types from "../types/HolidayType";

export const createHolidayRequest = (payload) => ({
    type: Types.CREATE_HOLIDAY_REQUEST,
    payload,
})

export const createHolidaySuccess = (result) => ({
    type: Types.CREATE_HOLIDAY_SUCCESS,
    result,
})

export const updateHolidayRequest = (payload) => ({
    type: Types.UPDATE_HOLIDAY_REQUEST,
    payload,
})

export const updateHolidaySuccess = (result) => ({
    type: Types.UPDATE_HOLIDAY_SUCCESS,
    result,
})

export const getHolidaysRequest = (filter) => ({
    type: Types.GET_HOLIDAYS_REQUEST,
    filter,
})

export const getHolidaysSuccess = (result) => ({
    type: Types.GET_HOLIDAYS_SUCCESS,
    result,
})

export const deleteHolidayRequest = (params) => ({
    type: Types.DELETE_HOLIDAY_REQUEST,
    params,
})

export const deleteHolidaySuccess = (result) => ({
    type: Types.DELETE_HOLIDAY_SUCCESS,
    result,
})