const menu = {
  HOME: {
    VIEW: {
      permission: "dashboard",
      route: "/",
      group: "home",
    },
  },
  COMPANY: {
    VIEW_OVERVIEW: {
      permission: "details",
      route: "/company-profile/details",
      group: "company",
    },
  },
  PEOPLE: {
    VIEW: {
      permission: "list",
      route: "/employees",
      group: "employees",
    },

    VIEW_DIRECTORY: {
      permission: "directory",
      route: "/employees/directory",
      group: "employees",
    },
    VIEW_ORG_CHART: {
      permission: "org_chart",
      route: "/employees/orgchart",
      group: "employees",
    },
  },
  EMPLOYEE: {
    VIEW_PERSONAL_INFO: {
      permission: "personal_info",
      route: "/employees/:id/personal-info",
      group: "employee",
    },
    VIEW_JOB_INFO: {
      permission: "personal_info",
      route: "/employees/:id/job-info/",
      group: "employee",
    },
    VIEW_EMERGENCY_CONTACT: {
      permission: "emergency_contact",
      route: "/employees/:id/emergency-contacts",
      group: "employee",
    },
    VIEW_BENEFITS: {
      permission: "benefits",
      route: "/employee/:id/benefits",
      group: "employee",
    },
    VIEW_ASSETS: {
      permission: "assets",
      route: "/employee/:id/assets",
      group: "employee",
    },
    VIEW_TIME_OFFS: {
      permission: "time_off",
      route: "/employee/:id/time-off",
      group: "employee",
    },
    VIEW_PAY_STUBS: {
      permission: "pay_stubs",
      route: "/employee/:id/pay-stubs",
      group: "employee",
    },
  },
  BENEFIT: {
    VIEW_PLANS: {
      permission: "plans",
      route: "/benefits/plans",
      group: "benefit",
    },
    VIEW_CATEGORIES: {
      permission: "categories",
      route: "/benefits/categories",
      group: "benefit",
    },
  },
  FILES: {
    MANEGEMENT: {
      permission: "file_management",
      route: "/files",
      group: "files",
    },
  },
  TIME_OFF: {
    VIEW_POLICIES: {
      permission: "list_policy",
      route: "/time_off",
      group: "time_off",
    },
  },
  PAY_ROLL: {
    PEOPLE: {
      permission: "people",
      route: "/eligile_people",
      group: "payroll",
    },
    PAY_RUNS: {
      permission: "pay_runs",
      route: "/pay_runs",
      group: "payroll",
    },
  },
  APPROVAL: {
    VIEW_REQUESTS: {
      permission: "requests",
      route: "/approval",
      group: "approval",
    },
    VIEW_APPROVAL_HISTORIES: {
      permission: "histories",
      route: "/time-off-histories",
      group: "approval",
    },
    VIEW_HOLIDAY: {
      permission: "holidays",
      route: "/holidays",
      group: "approval"
    }
  },

  PAY_SCHEDULE: {
    VIEW_LIST: {
      permission: "list",
      route: "/pay-schedule",
      group: "pay_schedule",
    },
  },
  ME: {
    VIEW_PERSONAL_INFO: {
      permission: "personal_info",
      route: "/me/personal-info",
      group: "me",
    },
    VIEW_JOB_INFO: {
      permission: "personal_info",
      route: "/me/job-info",
      group: "me",
    },
    VIEW_EMERGENCY_CONTACT: {
      permission: "emergency_contact",
      route: "/me/emergency-contacts",
      group: "me",
    },
    VIEW_BENEFITS: {
      permission: "benefits",
      route: "/me/benefits",
      group: "me",
    },
    VIEW_ASSETS: {
      permission: "assets",
      route: "/me/assets",
      group: "me",
    },
    VIEW_TIME_OFFS: {
      permission: "time_off",
      route: "/me/time-off",
      group: "me",
    },
    VIEW_PAY_STUBS: {
      permission: "pay_stubs",
      route: "/me/pay-stubs",
      group: "me",
    },
  },
  ADMIN: {
    MANAGE_COMPANIES: {
      permission: "list",
      route: "/admin/companies",
      group: "companies",
    },
    MANAGE_CUSTOMERS: {
      permission: "account-management",
      route: "/super-admin/customer-accounts",
      group: "customers",
    },
    MANAGE_USERS: {
      permission: "account-management",
      route: "/super-admin/admin-accounts",
      group: "users",
    },
    MANAGE_DEPARTMENTS: {
      permission: "department_management",
      route: "/super-admin/departments",
      group: "departments",
    },
  },
};

export default menu;
