const Urls = {
  PRE_SIGNIN: "/api/v1/pre-signin",
  SIGNIN: "/api/v1/signin",
  REGISTER: "/api/v1/register",
  SIGNOUT: "/api/v1/signout",
  ME: "/api/v1/me",
  UPDATE_PROFILE: "/api/v1/admin/profile/update",
  REFRESH: "api/admin/user/refresh",
  VERIFY_ROUTE: "/api/v1/check",

  EMPLOYEE_LIST: "/api/v1/employees",
  EMPLOYEE_ORG: "/api/v1/inferiors",
  EMPLOYEE_GET_PERSONAL_INFO: "/api/v1/employees/:id/personal-info",
  EMPLOYEE_PUT_PERSONAL_INFO: "/api/v1/employees/:id/personal-info",

  EMPLOYEE_GET_JOB_INFO: "/api/v1/employees/:id/job-informations",
  EMPLOYEE_POST_JOB_INFO: "/api/v1/employees/:id/job-informations",
  EMPLOYEE_DELETE_JOB_INFO: "/api/v1/employees/job-informations/:id",

  GET_EMPLOYEE_EMPLOYMENT_STATUS: "/api/v1/employees/:id/employment-status",
  EMPLOYEE_POST_EMPLOYMENT_STATUS: "/api/v1/employees/:id/employment-status",
  EMPLOYEE_DELETE_EMPLOYMENT_STATUS: "/api/v1/employees/employment-status/:id",

  GET_EMPLOYEE_COMPENSATION: "api/v1/employees/:id/compensations",
  EMPLOYEE_POST_COMPENSATION: "api/v1/employees/:id/compensations",
  EMPLOYEE_DELETE_COMPENSATION: "api/v1/employees/compensations/:id",

  EMPLOYEE_GET_BENEFITS: "/api/v1/employees/:id/benefits",
  EMPLOYEE_GET_MANAGERS: "/api/v1//managers",

  EMPLOYEE_GET_EMERGENCY_CONTACT: "/api/v1/employees/:id/emergency-contacts",
  EMPLOYEE_POST_EMERGENCY_CONTACT: "/api/v1/employees/:id/emergency-contacts",
  EMPLOYEE_PUT_EMERGENCY_CONTACT: "/api/v1/employees/emergency-contacts/:id",
  EMPLOYEE_DELETE_EMERGENCY_CONTACT: "/api/v1/employees/emergency-contacts/:id",
  EMPLOYEE_ADD: "/api/v1/add-employee",

  EMPLOYEE_GET_TIMEOFFS: "/api/v1/employees/:id/timeoff-requests",
  EMPLOYEE_GET_TIMEOFFS_HISTORIES: "/api/v1/employees/:id/timeoff-histories",
  EMPLOYEE_PUT_TIMEOFF: "/api/v1/employees/timeoff-requests/:id",
  EMPLOYEE_DELETE_TIMEOFF: "/api/v1/employees/timeoff-requests/:id",

  CHANGE_PASSWORD_EMPLOYEE: "api/v1/employees/change-password",
  RESET_PASSWORD_EMPLOYEE: "api/v1/employees/reset-password",
  CHANGE_STATUS_EMPLOYEE: "api/v1/employees/active",

  BENEFIT_GET_PLANS: "api/v1/benefits-management",
  BENEFIT_ADD_PLAN: "api/v1/add-benefit",
  BENEFIT_EDIT_PLAN: "api/v1/benefits-management/:id",
  BENEFIT_DELETE_PLAN: "api/v1/benefits-management/:id",

  GET_BENEFIT_CATEGORIES: "api/v1/benefit-categories",
  CREATE_BENEFIT_CATEGORY: "api/v1/benefit-categories",
  UPDATE_BENEFIT_CATEGORY: "api/v1/benefit-categories/:id",
  DELETE_BENEFIT_CATEGORY: "api/v1/benefit-categories/:id",

  FILE_GET_FOLDERS: "api/v1/folders",
  FILE_ADD_FOLDER: "api/v1/folders",
  FILE_EDIT_FOLDER: "api/v1/folders/:id",
  FILE_DELETE_FOLDER: "api/v1/folders/:id",
  FILE_GET_LIST: "api/v1/files",
  FILE_DELETE_ITEM: "api/v1/files",
  FILE_UPLOAD: "api/v1/upload",

  TIME_OFF_GET_CATEGORIES: "api/v1/timeoff-categories",
  TIME_OFF_ADD_CATEGORY: "api/v1/timeoff-categories",
  TIME_OFF_EDIT_CATEGORY: "api/v1/timeoff-categories/:id",
  TIME_OFF_DELETE_CATEGORY: "api/v1/timeoff-categories/:id",
  TIME_OFF_REQUEST: "api/v1/employees/timeoff-requests",

  GET_PEOPLE_ON_LEAVE_DAYS: "api/v1/dayoff-calendar",
  GET_EMPLOYEE_TIME_OFFS_HISTORIES: "api/v1/employees/:id/timeoff-histories",

  GET_TIME_OFF_APPROVALS: "api/v1/managers/timeoff-requests",
  GET_TIME_OFF_APPROVAL_HISTORIES: "api/v1/managers/timeoff-histories",
  PERMIT_TIME_OFF_APPROVAL: "api/v1/managers/timeoff-requests/approval",

  PAY_SCHEDULE_GET_LIST: "api/v1/pay-schedules",
  PAY_SCHEDULE_ADD_ITEM: "api/v1/pay-schedules",
  PAY_SCHEDULE_EDIT_ITEM: "api/v1/pay-schedules/:id",
  PAY_SCHEDULE_DELETE_ITEM: "api/v1/pay-schedules/:id",

  GET_EMPLOYEES_ON_PAY_ROLL: "api/v1/employees-on-payroll",
  GET_DRAFT_PAY_RUNS: "api/v1/payruns/drafts",
  GET_APPROVED_PAY_RUNS: "api/v1/payruns/approved",
  GET_PAID_PAY_RUNS: "api/v1/payruns/paid",
  APPROVE_DRAFT_PAY_RUN: "api/v1/payruns/drafts/approval",
  REVERT_APPROVED_PAY_RUN: "api/v1/payruns/approved/revert-to-draft",

  GET_EMPLOYEE_PAY_STUBS: "api/v1/employees/:id/paystubs",
  GET_EMPLOYEE_PAY_STUB: "api/v1/payruns/earnings-deductions-employee/:id",

  ADD_EMPLOYEE_ON_PAY_ROLL: "api/v1/add-employee-on-payroll/:id",
  REMOVE_EMPLOYEE_ON_PAY_ROLL: "api/v1/remove-employee-on-payroll/:id",

  UPDATE_PAY_RUN: "api/v1/payruns/drafts/:id",
  DELETE_PAY_RUN: "api/v1/payruns/drafts/:id",

  ADD_EMPLOYEE_IN_PAY_RUN: "api/v1/payruns/employee-payrun/:id",
  UPDATE_EMPLOYEE_IN_PAY_RUN: "api/v1/payruns/employee-on-payrun/:id",

  ADD_OFF_CYCLE_RUN: "api/v1/add-off-cycle-run",

  GET_EARNINGS: "api/v1/earning-categories",
  GET_DEDUCTIONS: "api/v1/deduction-categories",
  CREATE_EARNING_OR_DEDUCTION: "api/v1/earning-deductions-categories",
  UPDATE_EARNING_OR_DEDUCTION: "api/v1/earning-deductions-categories/:id",
  DELETE_EARNING_OR_DEDUCTION: "api/v1/earning-deductions-categories/:id",

  UPLOAD_AVATAR: "api/v1/upload-avatar",
  CHANGE_EMPLOYEE_ROLE: "api/v1/employees/:id/roles",

  // Companies
  GET_COMPANIES: "api/v1/super-admin/companies",
  GET_COMPANY: "api/v1/company",
  CREATE_COMPANY: "api/v1/super-admin/companies",
  UPDATE_COMPANY: "api/v1/company",
  DELETE_COMPANY: "api/v1/super-admin/companies/:id",
  UPLOAD_COMPANY_LOGO: "api/v1/upload-logo",
  CHANGE_COMPANY_STATUS: "api/v1/super-admin/companies/:id",

  // Customers Account
  GET_CUSTOMERS: "api/v1/super-admin/employee-companies",
  CREATE_CUSTOMER: "api/v1/super-admin/companies/:company_id/employees",
  UPDATE_CUSTOMER: "api/v1/super-admin/employees-company/:employee_id",
  DELETE_CUSTOMER: "api/v1/",

  // Admin Account
  GET_ADMIN_ACCOUNTS: "api/v1/super-admin/users",
  CREATE_ADMIN_ACCOUNT: "api/v1/super-admin/users",
  UPDATE_ADMIN_ACCOUNT: "api/v1/super-admin/users/:id",

  // Department Account
  GET_DEPARTMENTS: "api/v1/departments",
  CREATE_DEPARTMENT: "api/v1/departments",
  UPDATE_DEPARTMENT: "api/v1/departments/:departmentId",
  DELETE_DEPARTMENT: "api/v1/departments/:departmentId",

  // Holiday
  GET_HOLIDAY: "api/v1/holidays",
  CREATE_HOLIDAY: "api/v1/holidays",
  UPDATE_HOLIDAY: "api/v1/holidays/:id",
  DELETE_HOLIDAY: "api/v1/holidays/:id",
};

export default Urls;
