import ApiService from "../../utils/ApiService";
import Urls from "../../utils/Urls";

export function createHoliday(payload) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.CREATE_HOLIDAY,
        parser: (data) => data.data.result,
    }).post(payload);
}

export function updateHoliday(payload) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.UPDATE_HOLIDAY,
        endpointParams: { id: payload?.id },
        parser: (data) => data.data.result,
    }).put(payload);
}

export function getHolidays() {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.GET_HOLIDAY,
        parser: (data) => data.data.result,
    }).get();
}

export function deleteHoliday(params) {
    return new ApiService({
        baseURL: process.env.REACT_APP_API_URL,
        endpoint: Urls.DELETE_HOLIDAY,
        endpointParams: { id: params?.id },
    }).delete();
}