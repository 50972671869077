import * as Types from "../types/HolidayType";
const initialState = {
    items: [],
};

const holidayReducer = (state = initialState, action) => {
    switch (action.type) {
        case Types.CREATE_HOLIDAY_SUCCESS: {
            const items = [action?.result, ...state?.items];
            return {
                ...state,
                items: items,
            };
        };
        case Types.GET_HOLIDAYS_SUCCESS: {
            const items = action?.result;
            return {
                ...state,
                items: items,
            }
        }
        case Types.UPDATE_HOLIDAY_SUCCESS: {
            const items = state?.items?.map(x => {
                if(x?.id === action?.result?.id) {
                    return action?.result;
                }
                return x;
            });
            return {
                ...state,
                items: items,
            }
        }
        case Types.DELETE_HOLIDAY_SUCCESS: {
            const items = state?.items.filter(item =>
                item?.id !== action?.result?.id);
            return {
                ...state,
                items: items,
            }
        }
        default:
            return state;
    }
}

export default holidayReducer;