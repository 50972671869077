import { all } from "redux-saga/effects";
import { authSaga } from "./authSaga";
import { employeeSaga } from "./employeeSaga";
import { verifySaga } from "./verifySaga";
import { benefitSaga } from "./benefitSaga";
import { fileSaga } from "./fileSaga";
import { timeOffSaga } from "./timeOffSaga";
import { approvalSaga } from "./approvalSaga";
import { payScheduleSaga } from "./payScheduleSaga";
import { payRollSaga } from "./payRollSaga";
import { companySaga } from "./companySaga";
import { adminAccountSaga } from "./adminAccountSaga";
import { customerSaga } from "./customerSaga";
import { departmentSaga } from "./departmentSaga";
import { holidaySaga } from "./holidaySaga";


export default function* rootSaga() {
  yield all([
    authSaga(),
    verifySaga(),
    employeeSaga(),
    benefitSaga(),
    fileSaga(),
    timeOffSaga(),
    approvalSaga(),
    payScheduleSaga(),
    payRollSaga(),
    companySaga(),
    adminAccountSaga(),
    customerSaga(),
    departmentSaga(),
    holidaySaga(),
  ]);
}
