export const GET_TIME_OFF_CATEGORIES = "GET_TIME_OFF_CATEGORIES";
export const GET_TIME_OFF_CATEGORIES_REQUEST =
  "GET_TIME_OFF_CATEGORIES_REQUEST";
export const GET_TIME_OFF_CATEGORIES_SUCCESS =
  "GET_TIME_OFF_CATEGORIES_SUCCESS";
export const GET_TIME_OFF_CATEGORIES_FAILURE =
  "GET_TIME_OFF_CATEGORIES_FAILURE";

export const CREATE_TIME_OFF_CATEGORY = "CREATE_TIME_OFF_CATEGORY";
export const CREATE_TIME_OFF_CATEGORY_REQUEST =
  "CREATE_TIME_OFF_CATEGORY_REQUEST";
export const CREATE_TIME_OFF_CATEGORY_SUCCESS =
  "CREATE_TIME_OFF_CATEGORY_SUCCESS";
export const CREATE_TIME_OFF_CATEGORY_FAILURE =
  "CREATE_TIME_OFF_CATEGORY_FAILURE";

export const UPDATE_TIME_OFF_CATEGORY = "UPDATE_TIME_OFF_CATEGORY";
export const UPDATE_TIME_OFF_CATEGORY_REQUEST =
  "UPDATE_TIME_OFF_CATEGORY_REQUEST";
export const UPDATE_TIME_OFF_CATEGORY_SUCCESS =
  "UPDATE_TIME_OFF_CATEGORY_SUCCESS";
export const UPDATE_TIME_OFF_CATEGORY_FAILURE =
  "UPDATE_TIME_OFF_CATEGORY_FAILURE";

export const DELETE_TIME_OFF_CATEGORY = "DELETE_TIME_OFF_CATEGORY";
export const DELETE_TIME_OFF_CATEGORY_REQUEST =
  "DELETE_TIME_OFF_CATEGORY_REQUEST";
export const DELETE_TIME_OFF_CATEGORY_SUCCESS =
  "DELETE_TIME_OFF_CATEGORY_SUCCESS";
export const DELETE_TIME_OFF_CATEGORY_FAILURE =
  "DELETE_TIME_OFF_CATEGORY_FAILURE";

export const SEND_TIME_OFF = "SEND_TIME_OFF";
export const SEND_TIME_OFF_REQUEST = "SEND_TIME_OFF_REQUEST";
export const SEND_TIME_OFF_SUCCESS = "SEND_TIME_OFF_SUCCESS";
export const SEND_TIME_OFF_FAILURE = "SEND_TIME_OFF_FAILURE";

export const GET_PEOPLE_ON_LEAVE_DAYS = "GET_PEOPLE_ON_LEAVE_DAYS";
export const GET_PEOPLE_ON_LEAVE_DAYS_REQUEST =
  "GET_PEOPLE_ON_LEAVE_DAYS_REQUEST";
export const GET_PEOPLE_ON_LEAVE_DAYS_SUCCESS =
  "GET_PEOPLE_ON_LEAVE_DAYS_SUCCESS";
export const GET_PEOPLE_ON_LEAVE_DAYS_FAILURE =
  "GET_PEOPLE_ON_LEAVE_DAYS_FAILURE";

export const GET_EMPLOYEES_TIME_OFF_HISTORIES =
  "GET_EMPLOYEES_TIME_OFF_HISTORIES";
export const GET_EMPLOYEES_TIME_OFF_HISTORIES_REQUEST =
  "GET_EMPLOYEES_TIME_OFF_HISTORIES_REQUEST";
export const GET_EMPLOYEES_TIME_OFF_HISTORIES_SUCCESS =
  "GET_EMPLOYEES_TIME_OFF_HISTORIES_SUCCESS";
export const GET_EMPLOYEES_TIME_OFF_HISTORIES_FAILURE =
  "GET_EMPLOYEES_TIME_OFF_HISTORIES_FAILURE";

export const GET_EMPLOYEE_TIME_OFF_HISTORIES =
  "GET_EMPLOYEE_TIME_OFF_HISTORIES";
export const GET_EMPLOYEE_TIME_OFF_HISTORIES_REQUEST =
  "GET_EMPLOYEE_TIME_OFF_HISTORIES_REQUEST";
export const GET_EMPLOYEE_TIME_OFF_HISTORIES_SUCCESS =
  "GET_EMPLOYEE_TIME_OFF_HISTORIES_SUCCESS";
export const GET_EMPLOYEE_TIME_OFF_HISTORIES_FAILURE =
  "GET_EMPLOYEE_TIME_OFF_HISTORIES_FAILURE";

export const UPDATE_PEOPLE_ON_LEAVE_DAYS_REQUEST =
    "UPDATE_PEOPLE_ON_LEAVE_DAYS_REQUEST";
export const UPDATE_PEOPLE_ON_LEAVE_DAYS_SUCCESS =
    "UPDATE_PEOPLE_ON_LEAVE_DAYS_SUCCESS";
export const UPDATE_PEOPLE_ON_LEAVE_DAYS_FAILURE =
    "UPDATE_PEOPLE_ON_LEAVE_DAYS_FAILURE";

export const CREATE_PEOPLE_ON_LEAVE_DAYS_REQUEST =
    "CREATE_PEOPLE_ON_LEAVE_DAYS_REQUEST";
export const CREATE_PEOPLE_ON_LEAVE_DAYS_SUCCESS =
    "CREATE_PEOPLE_ON_LEAVE_DAYS_SUCCESS";
export const CREATE_PEOPLE_ON_LEAVE_DAYS_FAILURE =
    "CREATE_PEOPLE_ON_LEAVE_DAYS_FAILURE";

export const DELETE_PEOPLE_ON_LEAVE_DAYS_REQUEST =
    "DELETE_PEOPLE_ON_LEAVE_DAYS_REQUEST";
export const DELETE_PEOPLE_ON_LEAVE_DAYS_SUCCESS =
    "DELETE_PEOPLE_ON_LEAVE_DAYS_SUCCESS";
export const DELETE_PEOPLE_ON_LEAVE_DAYS_FAILURE =
    "DELETE_PEOPLE_ON_LEAVE_DAYS_FAILURE";