export const CREATE_HOLIDAY = "CREATE_HOLIDAY";
export const CREATE_HOLIDAY_REQUEST =
    "CREATE_HOLIDAY_REQUEST";
export const CREATE_HOLIDAY_SUCCESS = "CREATE_HOLIDAY_SUCCESS";
export const CREATE_HOLIDAY_FAILURE =
    "CREATE_HOLIDAY_FAILURE";
export const GET_HOLIDAYS = "GET_HOLIDAYS";
export const GET_HOLIDAYS_REQUEST =
    "GET_HOLIDAYS_REQUEST";
export const GET_HOLIDAYS_SUCCESS =
    "GET_HOLIDAYS_SUCCESS";
export const GET_HOLIDAYS_FAILURE =
    "GET_HOLIDAYS_FAILURE";
export const UPDATE_HOLIDAY = "UPDATE_HOLIDAY";
export const UPDATE_HOLIDAY_REQUEST =
    "UPDATE_HOLIDAY_REQUEST";
export const UPDATE_HOLIDAY_SUCCESS =
    "UPDATE_HOLIDAY_SUCCESS";
export const UPDATE_HOLIDAY_FAILURE =
    "UPDATE_HOLIDAY_FAILURE";
export const DELETE_HOLIDAY = "DELETE_HOLIDAY";
export const DELETE_HOLIDAY_REQUEST =
    "DELETE_HOLIDAY_REQUEST";
export const DELETE_HOLIDAY_SUCCESS =
    "DELETE_HOLIDAY_SUCCESS";
export const DELETE_HOLIDAY_FAILURE =
    "DELETE_HOLIDAY_FAILURE";